import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IMyDrpOptions } from 'mydaterangepicker';
import { GridOptions } from 'ag-grid-community';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ClientService } from '../../../services/fleet/client.service';
import { FormBuilder } from '@angular/forms';
import { InvoiceService } from '../../../services/billing/invoice.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import Swal from 'sweetalert2';
import { CompanyService, ScheduleDetailsService } from '../../../services/services.index';
import { ReportsManageService } from '../../../services/reports-manage.service';
import { AuthService } from '../../../services/auth.service';
import AgGridHelpers from '../../../helpers/ag-grid-helpers';
import { takeUntil } from 'rxjs/operators';
import { ClientsId, ReportsId } from '../unprocessed-runs/unprocessed-runs.interface';

@Component({
  selector: 'app-interruption-details',
  templateUrl: './interruption-details.component.html',
  styleUrls: ['./interruption-details.component.scss']
})

export class InterruptionDetailsBillingComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  dataParams: any = {};
  @Input() onClearGrid? = false;
  private destroy: Subject<boolean> = new Subject<boolean>();

  @ViewChild('modal') modal: BsModalComponent;
  nameGrid = 'gridInterruptionDetailBilling';

  dataInterruption: any = {};
  grid: GridOptions;
  busy: Subscription[] = [];
  date = new Date();
  myDateRangePickerOptions: IMyDrpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate() }
  };
  dropdownSettings: any = {
    singleSelection: false,
    idField: 'id',
    textField: 'description_route',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };
  actualDate = new Date();
  clients: any = [];
  routes: any = [];
  count: any = {};
  getContextMenuItems;

  constructor(
    private clientService: ClientService,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    private scheduleDetailsService: ScheduleDetailsService,
    private reportsManageService: ReportsManageService,
    private authService: AuthService,
    private companyService: CompanyService
  ) {
    this.initGrid();
  }

  ngOnInit() {
    this.loadData();
    this.invoiceService.load_interruptions$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.dataParams = res;
      this.onSearch();
    });
    this.invoiceService.new_interruptions$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.dataInterruption = res;
      this.newInterrruption();
    });
    this.invoiceService.save_layout_runs$.pipe(takeUntil(this.destroy)).subscribe(res => {
      this.saveGridLayout();
    });
  }

  ngAfterViewInit() {
    this.loadConfGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['onClearGrid']) {
      if (this.onClearGrid && this.grid.api) {
        this.grid.api.setRowData([]);
      }
    }
  }

  loadConfGrid() {
    this.busy.push(this.scheduleDetailsService.getConfigGrid(this.nameGrid).subscribe(
      data => {
        if (data.data && this.grid.columnApi) {
          this.grid.columnApi.setColumnState(JSON.parse(data.data.config));
        }
      }
    ));
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    return this.busy = [];
  }

  initGrid() {
    this.grid = <GridOptions>{
      enableSorting: true,
      enableColResize: true,
      sortingOrder: ['desc', 'asc', null],
      rowSelection: 'single',
      enableRangeSelection: true,
      enableFilter: true,
      onGridReady: () => {
        this.grid.api.setRowData([]);
      },
      columnDefs: [
        {
          headerName: 'GENERAL',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: 'N°', field: 'no', width: 50 },
            { headerName: 'Billing categories', field: 'detail_full', width: 300 },
            { headerName: 'BUS #', field: 'bus_number', width: 100, cellStyle: { 'text-align': 'center' } },
            { headerName: 'Day-Date', field: 'date', width: 200 },
            { headerName: 'ROUTE', field: 'rute', width: 200, cellStyle: { 'text-align': 'center' } },
            { headerName: 'BLOCK', field: 'block', width: 200, cellStyle: { 'text-align': 'center' } },
            { headerName: 'TIME OUT', field: 'time_out', width: 90, cellStyle: { 'text-align': 'right' } },
            { headerName: 'TIME IN', field: 'time_in', width: 90, cellStyle: { 'text-align': 'right' } },
          ]
        },
        {
          headerName: 'DETAILS',
          cellStyle: { 'text-align': 'center' },
          children: [
            { headerName: '(minutes)', field: 'total_time_lost', width: 100, cellStyle: { 'text-align': 'right' } },
            { headerName: '(decimal hours)', field: 'total_hours', width: 130, cellStyle: { 'text-align': 'right' } },
          ]
        }
      ]
    };

    this.getContextMenuItems = (params) => {
      const x = this.grid;
      const result = [
        AgGridHelpers.itemMenu('Print', 'fa-print', () => {
            this.printInterruptions();
        }),
        'separator',
        'copy',
        'export',
      ];
      return result;
    };
  }

  loadData() {
    this.busy.push(forkJoin([
      this.clientService.getClientStatus(true),
    ]).subscribe((resp: any[]) => {
      this.clients = resp[0].data;
    }));
  }

  autoSizeAll() {
    const allColumnIds = [];
    this.grid.columnApi.getAllColumns().forEach(function(column: any) {
      allColumnIds.push(column.colId);
    });
    this.grid.columnApi.autoSizeColumns(allColumnIds);
  }

  onSearch() {
    // const data = Object.assign({}, this.dataParams);
    const routes_id_any = [];
    routes_id_any.push(Number(this.dataParams.routes_id));
    const data: any = {
      start_date: this.dataParams.start_date,
      end_date: this.dataParams.end_date,
      client_id: this.dataParams.client_id,
      routes_id: routes_id_any
    };
    if (
      data.start_date
      && data.end_date
      && data.client_id
      && data.routes_id
    ) {
      this.busy.push(this.invoiceService.getInterruptions(data)
      .pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
        if (this.grid.api) {
          const filter = this.grid.api.getFilterModel();
          let n = 0;
          this.grid.api.setRowData(resp.rows.map(r =>  {
            n++;
            return {
              ...r,
              no: n,
            };
          }));
          this.grid.api.setFilterModel(filter);
          this.count = {
            rows: resp.rows.length,
            totals: resp.totals,
          };
          this.autoSizeAll();
        }
      }));
    }
  }

  saveGridLayout() {
    const model = this.grid.columnApi.getColumnState();
    this.busy.push(this.scheduleDetailsService.setConfigGrid(this.nameGrid, JSON.stringify(model)).subscribe(
      data => {
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Your layout has been saved',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {});
      }
    ));
  }

  OnCellDoubleClicked(event) {
    const data = Object.assign({}, this.dataParams);
    this.dataInterruption = {
      id: event.data.id,
      rute: event.data.rute,
      dataForm: data,
    };
    this.modal.open();
  }

  newInterrruption() {
    this.modal.open();
  }

  onCloseModal(data) {
    this.modal.close();
    this.onSearch();
  }

  printInterruptions() {
    const parameter: any = {
      'client_id': [
        `${this.dataParams.client_id}`
      ],
      'intr_date': [
        `${this.dataParams.start_date}`,
        `${this.dataParams.end_date}`
      ]
    };
    this.dataParams.block_like_run ? parameter.route_group_id = this.dataParams.route_group_id : parameter.route_id = this.dataParams.routes_id;
    let repId = this.getInterruptionsReportId();
    this.busy.push(this.reportsManageService.getReportTrip2(repId, parameter).subscribe((resp: string) => {
      this.reportsManageService.loadReportWindows(resp);
    }));
  }

  getInterruptionsReportId() {
    const client_id = Number(this.dataParams.client_id);
    switch (client_id) {
      case ClientsId.MiamiBeach:
        return ReportsId.InterruptionsReportMiamiBeach;
      case ClientsId.Doral:
        return ReportsId.InterruptionsReportDoral;
      case ClientsId.cityOfMiami:
        return ReportsId.InterruptionsReportCityOfMiami;
      case ClientsId.Mdt:
        return ReportsId.InterruptionsReportMdt;
      default:
        return 6202;
    }
  }

}
